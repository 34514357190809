import React, { useState } from "react";
import { InputSelect, SearchBox, Button } from "@bluesilodev/timhutcomponents";
import UserAddIcon from "assets/icon/UserAddSVG/UserAdd";
import FetchingAndError from "components/fetchingAndError";
import LeaveBalanceDataTable from "pages/leaveBalance/tables";
import axios from "services/axios";
import { useFetchLocationDepartmentQuery } from "store/apis/externalApi";
import { useFetchLeaveBalanceQuery } from "store/apis/leaveBalanceApi";
import { useFetchLeaveTypeQuery } from "store/apis/leaveTypeApi";

const dataTableDummy = [];
/*
const dataTableDummy = [
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Johandi",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Johandi",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Halim",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Halim",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Johandi",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Halim",
    uId: "298djdj",
    jobPosition: {
      position: "Cook",
      department: "Operation",
    },
    annualLeave: "12 Days",
    sickLeave: "5 Days",
    offInLine: "1 Days",
    ns: "5 Days",
  },
];
*/

const LeaveBalance = () => {
  // Data State
  const [allDataTable, setAllDataTable] = useState(dataTableDummy);
  const [dataTable, setDataTable] = useState(dataTableDummy);
  const [globalFilter, setGlobalFilter] = useState({ locations: [] });
  const [locations, setLocations] = useState([{ label: "", value: "" }]);
  const [departments, setDepartments] = useState([{ label: "", value: "" }]);
  const [paginate, setPaginate] = useState({
    totalData: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [filterData, setFilterData] = useState({
    search: "",
    year: 0,
    location: "",
    department: "",
  });
  // Element State
  const [timerStopTyping, setTimerStopTyping] = useState(null);
  const [isEnter, setEnter] = useState("#DD7224");
  // Redux Toolkit
  const {
    data: dataLocationDep,
    error: errorLocationDep,
    isFetching: isFetchingLocationDep,
  } = useFetchLocationDepartmentQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: dataLeaveType,
    error: errorLeaveType,
    isFetching: isFetchingLeaveType,
  } = useFetchLeaveTypeQuery(null, { refetchOnMountOrArgChange: true });
  const {
    data: dataLeaveBalance,
    error: errorLeaveExpired,
    isFetching: isFetchingLeaveExpired,
  } = useFetchLeaveBalanceQuery(
    { ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage },
    { refetchOnMountOrArgChange: true },
  );

  React.useEffect(() => {
    setDataTable((oldV) => {
      return allDataTable.filter((value) => {
        const searchTrue = filterData.search
          ? Object.keys(value).some((key) =>
              String(value[key]).includes(filterData.search),
            )
          : true;
        const departmentTrue = filterData.department
          ? value.jobPosition.department === filterData.department
          : true;
        const locationTrue = filterData.location
          ? value.location === filterData.location
          : true;
        return searchTrue && departmentTrue && locationTrue;
      });
    });
    const departmentTempData = globalFilter.locations
      .filter(
        (item) =>
          item.locationName === filterData.location ||
          filterData.location === "",
      )
      .flatMap((entry) => entry.departments)
      .map((item, index) => {
        return {
          label: item.department,
          value: item.department,
        };
      });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    });
    setDepartments(departmentFInal);
  }, [filterData]);

  React.useEffect(() => {
    if (dataLocationDep) {
      const department = [];
      const location = dataLocationDep.data.map((locationVal) => {
        locationVal.departments.forEach((departmentVal) => {
          if (
            department.find((value) => value.value === departmentVal.department)
          )
            return;
          department.push({
            value: departmentVal.department,
            label: departmentVal.department,
          });
        });
        return {
          value: locationVal.locationName,
          label: locationVal.locationName,
        };
      });
      setDepartments(department);
      setLocations(location);
      setGlobalFilter({ locations: dataLocationDep.data });
    }
  }, [dataLocationDep]);

  React.useEffect(() => {
    const year = new Date().getFullYear();
    console.log("leave balance data", dataLeaveBalance);
    setDataTable((oldVal) => {
      let returnData = [];
      if (dataLeaveBalance && dataLeaveType) {
        setPaginate((oldData) => ({
          ...oldData,
          totalData: dataLeaveBalance.totalDocs,
        }));
        const leaveType = dataLeaveType.data.map((val) => ({
          leaveTypeId: val.uId,
          leaveType: val.name,
        }));
        returnData = [
          ...dataLeaveBalance.data.map((val) => {
            const singleData = {
              // employee: val.employeeInformation.userName,
              employee:
                val.employeeInformation.firstName +
                " " +
                val.employeeInformation.lastName,
              employeeID: val.employeeInformation.employeeID,
              userID: val.userID,
              uId: val.uId,
              photo: val.employeeInformation.photo?.[0].link || "",
              jobPosition: {
                position:
                  val.employeeInformation?.userInformation?.employeementDetail
                    ?.jobPosition || "",
                department:
                  val.employeeInformation?.userInformation?.employeementDetail
                    ?.departments || "",
              },
            };
            leaveType.forEach((leaveTypeItem) => {
              const valBalance = val.balance[year].find(
                (type) => type.leaveTypeID === leaveTypeItem.leaveTypeId,
              );
              singleData[leaveTypeItem.leaveType] = {
                fullYearEntitlement: valBalance?.fullYearEntitlement,
                ytdBalance: valBalance?.ytdBalance,
              };
            });
            return singleData;
          }),
        ];
      } else {
        returnData = [];
      }
      setAllDataTable(returnData);
      return returnData;
    });
  }, [dataLeaveBalance, dataLeaveType]);

  const clickExportLeaveBalance = () => {
    axios
      .get(
        `/api/leave-balance/get?export=true&${Object.entries(
          {
            ...filterData,
            limit: paginate.rowsPerPage,
            page: paginate.currentPage,
          } || {},
        )
          .map((val) =>
            !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "",
          )
          .join("&")}`,
      )
      .then((response) => {
        const href = URL.createObjectURL(
          new Blob([response.data], { type: "text/csv;charset=utf-8," }),
        );
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Timhut_leave_balance.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const onChangeTypeSearch = (e) => {
    clearTimeout(timerStopTyping);
    const newTimer = setTimeout(() => {
      setFilterData((oldV) => ({ ...oldV, search: e.target.value }));
    }, 500);
    setTimerStopTyping(newTimer);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="App mt-5 p-1">
      <div className="w-full flex justify-between">
        <div className="flex">
          <div className="w-full mr-5">
            <div className="w-[320px]">
              {/*
              <InputDateRange label={"Year"} value={"26/10/2023"} onChange={() => {}} error={""} />
              */}
              <InputSelect
                title={"Year"}
                options={Array(50)
                  .fill()
                  .map((_, idx) => 1980 + idx)
                  .map((val) => ({ label: val, value: val }))}
                classname={"w-[320px] h-[55px]"}
                defaultValue={{ label: currentYear, value: currentYear }}
                onChange={(e) =>
                  setFilterData((oldV) => ({ ...oldV, year: e.target.value }))
                }
              />
            </div>
          </div>

          <InputSelect
            title={"Location"}
            options={locations}
            classname={"w-[200px] h-[55px] mr-5"}
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, location: e.target.value }))
            }
          />

          <InputSelect
            title={"Departement"}
            options={departments}
            classname={"w-[200px] h-[55px] mr-5"}
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, department: e.target.value }))
            }
          />
        </div>

        <div className="flex flex-row gap-5">
          <div className="h-[60px]">
            <SearchBox
              className={"w-[300px] h-full"}
              placeholder="Search"
              onChange={(e) => onChangeTypeSearch(e)}
            />
          </div>
          <Button
            className={"w-[150px]"}
            onMouseEnter={() => {
              setEnter("white");
            }}
            onMouseLeave={() => {
              setEnter("#DD7224");
            }}
            onClick={clickExportLeaveBalance}
            label={
              <div className="flex px-4 gap-1">
                <UserAddIcon color={isEnter} />
                <div>Export</div>
              </div>
            }
          />
        </div>
      </div>

      <div className=" mt-5">
        <FetchingAndError
          isFetching={isFetchingLeaveExpired && isFetchingLeaveType}
          isError={errorLeaveExpired && errorLeaveType}
        >
          <LeaveBalanceDataTable
            dataTable={dataTable}
            currentPage={paginate.currentPage}
            totalData={paginate.totalData}
            rowsPerPage={paginate.rowsPerPage}
            onChangeCurrentPage={(val) =>
              setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))
            }
            onChangeRowsPerPage={(val) =>
              setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
            }
            additionalLeaveTypeColumn={dataLeaveType ? dataLeaveType.data : []}
          />
        </FetchingAndError>
      </div>
    </div>
  );
};

export default LeaveBalance;
