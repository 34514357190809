import React, { useState } from "react";
import {
  Button,
  InputDateRange,
  InputSelect,
  SearchBox,
  WidgetCard,
} from "@bluesilodev/timhutcomponents";
import { useSelector } from "react-redux";
import CalendarIconSVG from "assets/icon/CalendarIconSVG/CalendarIconSVG";
import SaveIconSVG from "assets/icon/SaveIconSVG/SaveIconSVG";
import UserAddIcon from "assets/icon/UserAddSVG/UserAdd";
import FetchingAndError from "components/fetchingAndError";
import { LeaveReportsRequestLeaveModalForm } from "pages/leaveReports/forms";
import {
  LeaveReportsDataTable,
  LeaveReportsExcessiveDataTable,
} from "pages/leaveReports/tables";
import { useAlertApi } from "services/alert-api";
import axios from "services/axios";
import { useFetchLocationDepartmentQuery } from "store/apis/externalApi";
import {
  useDeleteLeaveMutation,
  useFetchLeaveQuery,
} from "store/apis/leaveReportApi";
import { useFetchLeaveTypeQuery } from "store/apis/leaveTypeApi";
import { useFetchLeaveUserTypeByTokenQuery } from "store/apis/leaveUserApi";

const dataTableDummy = [];
/*
const dataTableDummy = [
  {
    employee: "Halim",
    userID: "4567",
    location: "Cafe Halim",
    uId: "298d",
    jobPosition: {
      position: "Supervisor",
      departement: "Operation",
    },
    shift: "Office Working Hour",
    startTime: {
      time: "12:00",
      date: "24 August 2024",
    },
    endTime: {
      time: "15:00",
      date: "24 August 2024",
    },
    duration: "Half Shift(3 Hours)",
    leaveType: "Annual Leave",
    status: "Pending",
  },

  {
    employee: "Henry",
    userID: "3456",
    location: "Cafe Johandi",
    uId: "298d",
    jobPosition: {
      position: "Cook",
      departement: "Operation",
    },
    shift: "Office Working Hour",
    startTime: {
      time: "12:00",
      date: "24 August 2024",
    },
    endTime: {
      time: "15:00",
      date: "24 August 2024",
    },
    duration: "Half Shift(3 Hours)",
    leaveType: "Annual Leave",
    status: "Pending",
  },

  {
    employee: "Howard",
    userID: "2345",
    location: "Cafe Johandi",
    uId: "212355",
    jobPosition: {
      position: "Supervisor",
      departement: "Operation",
    },
    shift: "Office Working Hour",
    startTime: {
      time: "12:00",
      date: "24 August 2024",
    },
    endTime: {
      time: "15:00",
      date: "24 August 2024",
    },
    duration: "Half Shift(3 Hours)",
    leaveType: "Annual Leave",
    status: "Pending",
  },

  {
    employee: "Kelvin",
    userID: "1234",
    location: "Cafe Johandi",
    uId: "298d",
    jobPosition: {
      position: "Trainee",
      departement: "Operation",
    },
    shift: "Office Working Hour",
    startTime: {
      time: "12:00",
      date: "24 August 2024",
    },
    endTime: {
      time: "15:00",
      date: "24 August 2024",
    },
    duration: "Half Shift(3 Hours)",
    leaveType: "Annual Leave",
    status: "Approved",
  },
];
*/

const LeaveReports = () => {
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Data State
  const [allDataTable, setAllDataTable] = useState(dataTableDummy);
  const [dataTable, setDataTable] = useState(dataTableDummy);
  const [globalFilter, setGlobalFilter] = useState({ locations: [] });
  const [locations, setLocations] = useState([{ label: "", value: "" }]);
  const [departments, setDepartments] = useState([{ label: "", value: "" }]);
  const [paginate, setPaginate] = useState({
    totalData: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const [filterData, setFilterData] = useState({
    search: "",
    searchDateEnable: 0,
    startDate: new Date(),
    endDate: new Date(),
    location: "",
    department: "",
    approval: "",
  });
  const [currentFilter, setCurrentFilter] = useState("");
  const [deductFrom, setDeductFrom] = useState([
    { label: "Annual Leave", value: "Annual Leave" },
    { label: "Sick Leave", value: "Sick Leave" },
    { label: "Off In Line", value: "Off In Line" },
    { label: "NS", value: "NS" },
  ]);
  // Element State
  const [timerStopTyping, setTimerStopTyping] = useState(null);
  const [isEnter, setEnter] = useState("#DD7224");
  const [leaveReportRequestModal, leaveReportRequestModalOpen] =
    useState(false);
  // Redux Toolkit
  const {
    data: dataLocationDep,
    error: errorLocationDep,
    isFetching: isFetchingLocationDep,
  } = useFetchLocationDepartmentQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: dataLeaveUserType,
    error: errorLeaveUserType,
    isFetching: isFetchingLeaveUserType,
  } = useFetchLeaveUserTypeByTokenQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: dataLeaveType,
    error: errorLeaveType,
    isFetching: isFetchingLeaveType,
  } = useFetchLeaveTypeQuery(null, { refetchOnMountOrArgChange: true });
  const {
    data: dataLeave,
    error: errorLeave,
    isFetching: isFetchingLeave,
  } = useFetchLeaveQuery(
    { ...filterData, limit: paginate.rowsPerPage, page: paginate.currentPage },
    { refetchOnMountOrArgChange: true },
  );
  const [deleteLeave, deleteLeaveResults] = useDeleteLeaveMutation();

  // Static State
  /*
  const timeRanges = [
    "Today",
    "Past Week",
    "This Week",
    "Last Month",
    "This Month",
    "Coustom Date",
  ];
  */
  const approval = [
    { label: "Approved", value: "approved" },
    { label: "Pending", value: "pending" },
    { label: "Rejected", value: "rejected" },
  ];

  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 1);
  React.useEffect(() => {
    setFilterData((oldV) => ({
      ...oldV,
      searchDateEnable: oldV.searchDateEnable + 1,
      startDate: firstDay,
      endDate: lastDay,
    }));
  }, []);

  React.useEffect(() => {
    allDataTable.mainData &&
      allDataTable.excessiveData &&
      setDataTable((oldV) => {
        return allDataTable[
          currentFilter === "excessive" ? "excessiveData" : "mainData"
        ].filter((value) => {
          const specialFilterPending =
            currentFilter === "pending" ? value.status === "Pending" : true;
          const searchTrue = filterData.search
            ? Object.keys(value).some((key) =>
                String(value[key]).includes(filterData.search),
              )
            : true;
          const dateTrue =
            filterData.searchDateEnable >= 2 &&
            filterData.startDate &&
            filterData.endDate
              ? new Date(value.startTime.date) >=
                  new Date(filterData.startDate) &&
                new Date(value.endTime.date) <= new Date(filterData.endDate)
              : true;
          const departmentTrue = filterData.department
            ? value.jobPosition.department === filterData.department
            : true;
          const locationTrue = filterData.location
            ? value.location === filterData.location
            : true;
          const approvalTrue =
            currentFilter !== "pending" && filterData.approval
              ? value.status === filterData.approval
              : true;
          return (
            specialFilterPending &&
            searchTrue &&
            dateTrue &&
            departmentTrue &&
            locationTrue &&
            approvalTrue
          );
        });
      });
    const departmentTempData = globalFilter.locations
      .filter(
        (item) =>
          item.locationName === filterData.location ||
          filterData.location === "",
      )
      .flatMap((entry) => entry.departments)
      .map((item, index) => {
        return {
          label: item.department,
          value: item.department,
        };
      });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    });
    setDepartments(departmentFInal);
  }, [currentFilter, filterData]);

  React.useEffect(() => {
    if (
      dataLeaveUserType &&
      !Array.isArray(dataLeaveUserType.data) &&
      dataLeaveUserType.data?.leaveTypeSettings?.length > 0
    ) {
      const leaveType = dataLeaveUserType.data.leaveTypeSettings.map((val) => ({
        value: val.uId,
        label: val.name,
      }));
      setDeductFrom(leaveType);
      return;
    }
    if (dataLeaveType) {
      const leaveType = dataLeaveType.data.map((val) => ({
        value: val.uId,
        label: val.name,
      }));
      setDeductFrom(leaveType);
    }
  }, [dataLeaveType, dataLeaveUserType]);

  React.useEffect(() => {
    if (dataLocationDep) {
      const department = [];
      const location = dataLocationDep.data.map((locationVal) => {
        locationVal.departments.forEach((departmentVal) => {
          if (
            department.find((value) => value.value === departmentVal.department)
          )
            return;
          department.push({
            value: departmentVal.department,
            label: departmentVal.department,
          });
        });
        return {
          value: locationVal.locationName,
          label: locationVal.locationName,
        };
      });
      setDepartments(department);
      setLocations(location);
      setGlobalFilter({ locations: dataLocationDep.data });
    }
  }, [dataLocationDep]);

  React.useEffect(() => {
    if (dataLeave) {
      const switchCaseApproval = (status) => {
        switch (status) {
          case "approved":
            return "Approved";
          case "rejected":
            return "Rejected";
          case "pending":
            return "Pending";
          default:
            return "Pending";
        }
      };
      setPaginate((oldData) => ({
        ...oldData,
        totalData: dataLeave.totalDocs,
      }));
      const leave = dataLeave.data.mainData.map((val) => {
        const leaveTimes = [...val.leaveTimes].sort((a, b) => a.date > b.date);
        return {
          // employee: val.employeeInformation.userName,
          employee: `${val.employeeInformation?.firstName ?? ""} ${
            val.employeeInformation?.lastName ?? ""
          }`.trim(),
          employeeID: val.employeeInformation.employeeID,
          userID: val.userID,
          location:
            val.employeeInformation?.userInformation?.employeementDetail
              ?.locations || "",
          uId: val.uId,
          photo: val.employeeInformation.photo?.[0].link || "",
          jobPosition: {
            position:
              val.employeeInformation?.userInformation?.employeementDetail
                ?.jobPosition || "",
            department:
              val.employeeInformation?.userInformation?.employeementDetail
                ?.departments || "",
          },
          shift: "Office Working Hour",
          startTime: {
            time: leaveTimes[0].time || "00:00",
            date: leaveTimes[0].date,
          },
          endTime: {
            time: leaveTimes[leaveTimes.length - 1].time || "00:00",
            date: leaveTimes[leaveTimes.length - 1].date,
          },
          duration: val.totalDuration,
          leaveType: val.leaveType.name,
          approvalStatus: {
            bySupervisor: {
              status: switchCaseApproval(
                val.approvalStatus.bySupervisor.status,
              ),
            },
            byHr: {
              status: switchCaseApproval(val.approvalStatus.byHr.status),
            },
          },
          status:
            val.approvalStatus.byHr.status === "pending" ||
            val.approvalStatus.bySupervisor.status === "pending"
              ? "Pending"
              : val.approvalStatus.byHr.status === "approved"
              ? "Approved"
              : "Rejected",
        };
      });
      const excessiveLeave = dataLeave.data.excessiveData.map((val) => {
        return {
          excessiveLeaveCount: val.count,
          // employee: val.employeeInformation.userName,
          employee: `${val.employeeInformation?.firstName ?? ""} ${
            val.employeeInformation?.lastName ?? ""
          }`.trim(),
          employeeID: val.employeeInformation.employeeID,
          userID: val._id,
          location:
            val.employeeInformation?.userInformation?.employeementDetail
              ?.locations || "",
          uId: val._id,
          photo: val.employeeInformation.photo?.[0].link || "",
          jobPosition: {
            position:
              val.employeeInformation?.userInformation?.employeementDetail
                ?.jobPosition || "",
            department:
              val.employeeInformation?.userInformation?.employeementDetail
                ?.departments || "",
          },
          shift: "Office Working Hour",
          startTime: {
            time: undefined,
            date: undefined,
          },
          endTime: {
            time: undefined,
            date: undefined,
          },
          duration: undefined,
          leaveType: undefined,
          approvalStatus: {
            bySupervisor: {
              status: undefined,
            },
            byHr: {
              status: undefined,
            },
          },
          status: undefined,
        };
      });
      setAllDataTable({
        totalPendingRequest: dataLeave.data.totalPendingRequest,
        totalExcessiveLeave: dataLeave.data.totalExcessiveLeave,
        mainData: leave,
        excessiveData: excessiveLeave,
      });
      setDataTable(leave);
    }
  }, [dataLeave]);

  useAlertApi(deleteLeaveResults);

  const checkTimeRange = () => {
    const timeRange =
      document.getElementsByClassName("time-range")[0].childNodes[1].value;
    if (timeRange === "Coustom Date") {
    }
  };

  const clickExportLeaveReports = () => {
    axios
      .get(
        `/api/leave/get?export=true&${Object.entries(
          {
            ...filterData,
            limit: paginate.rowsPerPage,
            page: paginate.currentPage,
          } || {},
        )
          .map((val) =>
            !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "",
          )
          .join("&")}`,
      )
      .then((response) => {
        const href = URL.createObjectURL(
          new Blob([response.data], { type: "text/csv;charset=utf-8," }),
        );
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Timhut_leave_report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const clickFormRequestLeaveReports = () => {
    leaveReportRequestModalOpen(true);
  };

  const onChangeTypeSearch = (e) => {
    clearTimeout(timerStopTyping);
    const newTimer = setTimeout(() => {
      setFilterData((oldV) => ({ ...oldV, search: e.target.value }));
    }, 500);
    setTimerStopTyping(newTimer);
  };

  const onDeleteClickDataTable = (data) => {
    deleteLeave({
      leaveID: data.uId,
    });
  };

  return (
    <div className="w-full h-max relative mt-5 p-1">
      <LeaveReportsRequestLeaveModalForm
        dataTable={dataTable}
        leaveReportRequestModal={leaveReportRequestModal}
        leaveReportRequestModalOpen={leaveReportRequestModalOpen}
        deductFrom={deductFrom}
      />

      <div className="w-full flex justify-between">
        <div className="flex gap-5 mr-5">
          <div className="w-full">
            <div className="w-[350px]">
              <InputDateRange
                label={"Time Range"}
                value={[filterData.startDate, filterData.endDate]}
                setFieldValue={(_, resultArrayDate) => {
                  setFilterData((oldV) => ({
                    ...oldV,
                    searchDateEnable: oldV.searchDateEnable + 1,
                    startDate: resultArrayDate[0],
                    endDate: resultArrayDate[resultArrayDate.length - 1],
                  }));
                }}
                error={""}
              />
            </div>
          </div>
          {/*
          <InputSelect
            title={"Time Range"}
            options={timeRanges}
            classname={"w-full"}
          />
          */}

          <InputSelect
            title={"Location"}
            options={locations}
            classname={"w-[150px] h-[55px]"}
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, location: e.target.value }))
            }
          />

          <InputSelect
            title={"Departement"}
            options={departments}
            classname={"w-[150px] h-[55px]"}
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, department: e.target.value }))
            }
          />

          <InputSelect
            title={"Approval"}
            options={approval}
            classname={"w-[150px] h-[55px]"}
            onChange={(e) =>
              setFilterData((oldV) => ({ ...oldV, approval: e.target.value }))
            }
          />
        </div>

        <div className="flex flex-row gap-5">
          <SearchBox
            className={"w-[300px]"}
            placeholder="Search"
            onChange={(e) => onChangeTypeSearch(e)}
          />

          {currentRole === "Admin" ? (
            <Button
              className={"w-[150px]"}
              onMouseEnter={() => {
                setEnter("white");
              }}
              onMouseLeave={() => {
                setEnter("#DD7224");
              }}
              onClick={clickExportLeaveReports}
              label={
                <div className="flex px-4 gap-1">
                  <UserAddIcon color={isEnter} />
                  <div>Export</div>
                </div>
              }
            />
          ) : (
            <Button
              className={"w-full"}
              label={
                <div className="flex items-center px-4 gap-1">
                  <SaveIconSVG color={"white"} />
                  <div>Request Leave</div>
                </div>
              }
              style={"solid"}
              onClick={clickFormRequestLeaveReports}
            />
          )}
        </div>
      </div>

      <div className="flex gap-5 mt-5">
        <div className=" flex items-center justify-center">
          <WidgetCard
            height="130px"
            onClick={() => {
              currentFilter === "pending"
                ? setCurrentFilter("")
                : setCurrentFilter("pending");
            }}
            radius="lg"
            width="230px"
            className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${
              currentFilter === "pending" && "border-orange-500"
            }`}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="border-2 border-gray-300 rounded-full p-2">
                <CalendarIconSVG color="orange" />
              </div>
              <div>
                <p className="input-label">Pending Request</p>
                <p className="text-lg font-semibold">
                  {allDataTable.totalPendingRequest} Employees
                </p>
              </div>
            </div>
          </WidgetCard>
        </div>

        <div className=" flex items-center justify-center">
          <WidgetCard
            height="130px"
            onClick={() => {
              currentFilter === "excessive"
                ? setCurrentFilter("")
                : setCurrentFilter("excessive");
            }}
            radius="lg"
            width="230px"
            className={`cursor-pointer rounded-lg shadow-xl flex items-center justify-center border-gray-300 border-2 ${
              currentFilter === "excessive" && "border-orange-500"
            }`}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="border-2 border-gray-300 rounded-full p-2">
                <CalendarIconSVG color="orange" />
              </div>
              <div>
                <p className="input-label">Excessive Leave</p>
                <p className="text-lg font-semibold">
                  {allDataTable.totalExcessiveLeave} Employees
                </p>
              </div>
            </div>
          </WidgetCard>
        </div>
      </div>

      <div className=" mt-5">
        <FetchingAndError
          isFetching={isFetchingLeave && isFetchingLeaveType}
          isError={errorLeave && errorLeaveType}
        >
          {currentFilter !== "excessive" && (
            <LeaveReportsDataTable
              dataTable={dataTable}
              currentPage={paginate.currentPage}
              totalData={paginate.totalData}
              rowsPerPage={paginate.rowsPerPage}
              onChangeCurrentPage={(val) =>
                setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))
              }
              onChangeRowsPerPage={(val) =>
                setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
              }
              onDeleteClick={onDeleteClickDataTable}
            />
          )}
          {currentFilter === "excessive" && (
            <LeaveReportsExcessiveDataTable
              dataTable={dataTable}
              currentPage={paginate.currentPage}
              totalData={paginate.totalData}
              rowsPerPage={paginate.rowsPerPage}
              onChangeCurrentPage={(val) =>
                setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))
              }
              onChangeRowsPerPage={(val) =>
                setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
              }
            />
          )}
        </FetchingAndError>
      </div>
    </div>
  );
};

export default LeaveReports;
