import React from "react";
import {
  DataTable,
  ModalDialog,
  Pagination,
} from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import InformCircleSVG from "assets/icon/InformCircleSVG/InformCircleSVG";

import TableMenu from "components/tableMenu";

const LeaveExpiredDataTable = ({
  dataTable,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
  additionalLeaveTypeColumn,
}) => {
  const navigate = useNavigate();
  const cells = (status) => {
    return <h1 className="cursor-pointer w-full">{status.getValue()}</h1>;
  };

  const cell = (status) => {
    if (status.getValue() !== null) {
      return (
        <div className="flex flex-row gap-1 hover:cursor-pointer">
          <h1>
            <span className="my-2">{`${
              status.getValue().unusedBroughtForward
            } Day`}</span>
          </h1>
          <div
            className="group flex relative"
            onMouseEnter={() => {
              return (
                <div className="bg-red-500 fixed">
                  <ModalDialog />
                </div>
              );
            }}
          >
            <InformCircleSVG color={"black"} />
            <span
              class="group-hover:block transition-opacity bg-white px-1 text-sm text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 hidden m-4 mx-auto"
              style={{ zIndex: 1 }}
            >
              <div class="rounded overflow-hidden shadow-lg">
                <div class="px-6 py-4">
                  <div class="font-bold text-black text-xs whitespace-nowrap mb-2">
                    Leave Expired
                  </div>
                  <p class="font-bold text-orange-500 text-xs whitespace-nowrap">
                    (Unused Carry Over will expired on 01/
                    {status.getValue().expiredCarryOver}/
                    {new Date().getFullYear()})
                  </p>
                </div>
              </div>
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>-</h1>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <DataTable
        maxHeight={450}
        columns={[
          {
            id: "employee",
            accessorFn: (row) => row,
            enableSorting: undefined,
            header: () => <span>Employee</span>,
            noPadding: true,
            cell: (status) => {
              const employee = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center h-[20px]">
                  <img
                    src={employee.photo}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <span>{employee.employee}</span>
                </div>
              );
            },
          },
          {
            id: "uId",
            accessorFn: (row) => <span>{row.employeeID}</span>,
            header: () => <span>Employee ID</span>,
            enableSorting: true,
            cell: (status) => {
              return cells(status);
            },
          },
          {
            id: "jobPosition",
            accessorFn: (row) => row.jobPosition,
            header: () => <span>Job Position</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              // const index = status.row.index;
              const department = status.getValue().department;
              return (
                <div className="w-full cursor-pointer h-[24px]">
                  <h1 className="">{status.getValue().position}</h1>
                  <h1 className="text-s text-gray-400">{department}</h1>
                </div>
              );
            },
          },
          ...additionalLeaveTypeColumn.map((itemType) => {
            return {
              id: itemType.name,
              accessorFn: (row) =>
                row[itemType.name] !== undefined ? row[itemType.name] : null,
              header: () => <span>{itemType.name}</span>,
              enableSorting: true,
              cell: (status) => {
                return cell(status);
              },
            };
          }),
          /*
          {
            id: "annualLeave",
            accessorFn: (row) => row.annualLeave,
            header: () => <span>Annual Leave</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "sickLeave",
            accessorFn: (row) => row.sickLeave,
            header: () => <span>Sick Leave</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "offInLine",
            accessorFn: (row) => row.offInLine,
            header: () => <span>Off In Line</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "ns",
            accessorFn: (row) => row.ns,
            header: () => <span>NS</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          */
          {
            accessorFn: (row) => row.userID,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit h-[20px]">
                  <TableMenu
                    onDetail={() => {
                      navigate(`/leave-balance-details/${status.getValue()}`);
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default LeaveExpiredDataTable;
