import React from "react";
import {
  DataTable,
  ColorTag,
  Pagination,
  ModalConfirmation,
  ModalConfirmationV2,
} from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";

import TableMenu from "components/tableMenu";

export const LeaveReportsExcessiveDataTable = ({
  dataTable,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
}) => {
  const navigate = useNavigate();
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        maxHeight={450}
        columns={[
          {
            id: "employee",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>Employee</span>,
            noPadding: true,
            cell: (status) => {
              const employee = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center cursor-pointer">
                  <img
                    src={employee.photo}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <span>{employee.employee}</span>
                </div>
              );
            },
          },
          {
            id: "uId",
            accessorFn: (row) => <span>{row.uId}</span>,
            header: () => <span>Employee ID</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "jobPosition",
            accessorFn: (row) => row.jobPosition,
            header: () => <span>Job Position</span>,
            enableSorting: true,
            cell: (status) => {
              return (
                <div className="w-full cursor-pointer">
                  <h1 className="">{status.getValue().position}</h1>
                  <h1 className="text-s text-gray-500">
                    {status.getValue().departement}
                  </h1>
                </div>
              );
            },
          },
          {
            id: "leaveRecords",
            accessorFn: (row) => (
              <span className="font-semibold">
                {row.excessiveLeaveCount} Days Leave Records
              </span>
            ),
            header: () => <span>Leave Records</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status, "/leave-excessive");
            },
          },
          {
            accessorFn: (row) => row.userID,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  <TableMenu
                    onDetail={() => {
                      navigate(`/leave-excessive/${status.getValue()}`);
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export const LeaveReportsDataTable = ({
  dataTable,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
  onDeleteClick,
}) => {
  const navigate = useNavigate();
  const colorTag = (color, status, approvalStatus) => {
    return (
      <>
        <div
          className="font-semibold w-full cursor-pointer"
          style={{ color: "white" }}
        >
          <ColorTag label={approvalStatus} color={color} />
        </div>
      </>
    );
  };
  const cell = (status, navigatePath) => {
    return (
      <div className="w-full cursor-pointer">
        <h1 className="">{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        maxHeight={450}
        columns={[
          {
            id: "employee",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>Employee</span>,
            noPadding: true,
            cell: (status) => {
              const employee = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center cursor-pointerh-[20px]">
                  <img
                    src={employee.photo}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <span>{employee.employee}</span>
                </div>
              );
            },
          },
          {
            id: "uId",
            accessorFn: (row) => <span>{row.employeeID}</span>,
            header: () => <span>Employee ID</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "jobPosition",
            accessorFn: (row) => row.jobPosition,
            header: () => <span>Job Position</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              // const index = status.row.index;
              const department = status.getValue().department;
              return (
                <div className="w-full cursor-pointer h-[24px]">
                  <h1 className="">{status.getValue().position}</h1>
                  <h1 className="text-s text-gray-400">{department}</h1>
                </div>
              );
            },
          },
          {
            id: "shift",
            accessorFn: (row) => <span>{row.shift}</span>,
            header: () => <span>Shift</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "startTime",
            accessorFn: (row) => row.startTime,
            header: () => <span>Start Time</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              return (
                <div className="w-full cursor-pointer h-[24px]">
                  <h1 className="">{status.getValue().time}</h1>
                  <h1 className="text-s text-gray-500">
                    {status.getValue().date}
                  </h1>
                </div>
              );
            },
          },
          {
            id: "endTime",
            accessorFn: (row) => row.endTime,
            header: () => <span>End Time</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              return (
                <div className="w-full cursor-pointer h-[24px]">
                  <h1 className="">{status.getValue().time}</h1>
                  <h1 className="text-s text-gray-500">
                    {status.getValue().date}
                  </h1>
                </div>
              );
            },
          },
          {
            id: "duration",
            accessorFn: (row) => <span>{row.duration}</span>,
            header: () => <span>Durations</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "leaveType",
            accessorFn: (row) => <span>{row.leaveType}</span>,
            header: () => <span>Type of Leave</span>,
            enableSorting: false,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "statusApprovalSupervisor",
            header: () => <span>Supervisor Approval Status</span>,
            accessorFn: (row) => row.approvalStatus?.bySupervisor.status,
            enableSorting: false,
            noPadding: true,
            cell: (status) => {
              const approvalStatus = status.getValue();

              if (approvalStatus === "Approved") {
                return colorTag("green", status, approvalStatus);
              }

              if (approvalStatus === "Rejected") {
                return colorTag("red", status, approvalStatus);
              }

              if (approvalStatus === "Pending") {
                return colorTag("gray", status, approvalStatus);
              }
            },
          },
          {
            id: "statusApprovalHr",
            header: () => <span>Admin Approval Status</span>,
            accessorFn: (row) => row.approvalStatus?.byHr.status,
            enableSorting: false,
            noPadding: true,
            cell: (status) => {
              const approvalStatus = status.getValue();

              if (approvalStatus === "Approved") {
                return colorTag("green", status, approvalStatus);
              }

              if (approvalStatus === "Rejected") {
                return colorTag("red", status, approvalStatus);
              }

              if (approvalStatus === "Pending") {
                return colorTag("gray", status, approvalStatus);
              }
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              const supervisorApproval =
                status.getValue().approvalStatus.bySupervisor.status;
              const adminApproval =
                status.getValue().approvalStatus.byHr.status;

              // Determine if the delete action should be enabled
              const canDelete = adminApproval !== "Approved";

              return (
                <div className="cursor-pointer w-fit h-[20px]">
                  <TableMenuHandler
                    show={true}
                    onClickRedirect={() => {
                      navigate(
                        `/leave-details/${status.getValue().userID}/${
                          status.getValue().uId
                        }`,
                      );
                    }}
                    onDeleteClick={
                      supervisorApproval !== "Approved" &&
                      adminApproval !== "Approved" &&
                      (() => {
                        const findSingleLeaveRequest = dataTable.find(
                          (val) => val.uId === status.getValue().uId,
                        );
                        onDeleteClick(findSingleLeaveRequest);
                      })
                    }
                    canDelete={canDelete}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

function TableMenuHandler({ show, onClickRedirect, onDeleteClick, canDelete }) {
  const [showModal, setShowModal] = React.useState(false);
  const confirmModal = () => {
    setShowModal(false);
    onDeleteClick();
  };
  const cancelModal = () => {
    setShowModal(false);
  };
  return (
    <React.Fragment>
      <TableMenu
        onDetail={onClickRedirect}
        // onDelete={() => setShowModal(true)}
        onDelete={canDelete ? () => setShowModal(true) : null}
      />
      {showModal && (
        // <ModalConfirmation
        //   headMessage="Are you sure want to delete it?"
        //   onClose={cancelModal}
        //   onConfirm={confirmModal}
        // />
        <ModalConfirmationV2
          className={"min-w-[600px]"}
          title={"Delete Leave"}
          headMessage={""}
          message={
            <div className="text-left w-full text-lg font-light">
              Do you want to delete this item?
            </div>
          }
          // onClose={() => setIsShow(false)}
          onClose={cancelModal}
          // onConfirm={() => {
          //   requestPayroll({ id: original?._id });
          //   dispatch(PayrollApi.util.invalidateTags(["payroll"]));
          //   setIsShow(false);
          // }}
          onConfirm={confirmModal}
        />
      )}
    </React.Fragment>
  );
}

export const LeaveReportsExcessiveDetailDataTable = ({
  dataTable,
  totalData,
  rowsPerPage,
  currentPage,
  onChangeCurrentPage,
  onChangeRowsPerPage,
}) => {
  const navigate = useNavigate();
  const colorTag = (color, status, approvalStatus) => {
    return (
      <>
        <div
          className="font-semibold w-full cursor-pointer"
          style={{ color: "white" }}
        >
          <ColorTag label={approvalStatus} color={color} />
        </div>
      </>
    );
  };
  const cell = (status) => {
    return (
      <div className="w-full cursor-pointer">
        <h1>{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        title="Leave Data"
        maxHeight={450}
        columns={[
          {
            id: "shift",
            accessorFn: (row) => <span>{row.shift}</span>,
            header: () => <span>Shift</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "startTime",
            accessorFn: (row) => row.startTime,
            header: () => <span>Start Time</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              return (
                <div className="w-full cursor-pointer">
                  <h1 className="">{status.getValue().time}</h1>
                  <h1 className="text-s text-gray-500">
                    {status.getValue().date}
                  </h1>
                </div>
              );
            },
          },
          {
            id: "endTime",
            accessorFn: (row) => row.endTime,
            header: () => <span>End Time</span>,
            enableSorting: true,
            noPadding: true,
            cell: (status) => {
              return (
                <div className="w-full cursor-pointer">
                  <h1 className="">{status.getValue().time}</h1>
                  <h1 className="text-s text-gray-500">
                    {status.getValue().date}
                  </h1>
                </div>
              );
            },
          },
          {
            id: "duration",
            accessorFn: (row) => <span>{row.duration}</span>,
            header: () => <span>Durations</span>,
            enableSorting: true,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "leaveType",
            accessorFn: (row) => <span>{row.leaveType}</span>,
            header: () => <span>Type of Leave</span>,
            enableSorting: false,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "statusApprovalSupervisor",
            header: () => <span>Supervisor Approval Status</span>,
            accessorFn: (row) => row.approvalStatus?.bySupervisor.status,
            enableSorting: false,
            noPadding: true,
            cell: (status) => {
              const approvalStatus = status.getValue();

              if (approvalStatus === "Approved") {
                return colorTag("green", status, approvalStatus);
              }

              if (approvalStatus === "Rejected") {
                return colorTag("red", status, approvalStatus);
              }

              if (approvalStatus === "Pending") {
                return colorTag("gray", status, approvalStatus);
              }
            },
          },
          {
            id: "statusApprovalHr",
            header: () => <span>Admin Approval Status</span>,
            accessorFn: (row) => row.approvalStatus?.byHr.status,
            enableSorting: false,
            noPadding: true,
            cell: (status) => {
              const approvalStatus = status.getValue();

              if (approvalStatus === "Approved") {
                return colorTag("green", status, approvalStatus);
              }

              if (approvalStatus === "Rejected") {
                return colorTag("red", status, approvalStatus);
              }

              if (approvalStatus === "Pending") {
                return colorTag("gray", status, approvalStatus);
              }
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit">
                  <TableMenu
                    onDetail={() => {
                      navigate(
                        `/leave-details/${status.getValue().userID}/${
                          status.getValue().uId
                        }`,
                      );
                    }}
                  />
                </div>
              );
            },
          },
        ]}
        data={dataTable.excessiveData}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export const LeaveReportsDetailDataTable = ({ dataTable, data }) => {
  const { photo, userName, totalLeaveDay } = data;
  const cell = (status) => {
    return (
      <div className="w-full">
        <h1>{status.getValue()}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        maxHeight={450}
        columns={[
          {
            id: "employee",
            accessorFn: (row) => row.employee,
            enableSorting: false,
            header: () => <span>Employee</span>,
            noPadding: true,
            cell: (status) => {
              const employeeName = status.getValue();
              return (
                <div className="flex flex-row gap-2 justify-center items-center ">
                  <img
                    src={photo}
                    alt="ProfileUser"
                    className="w-6 h-6 my-auto ml-4 rounded-full"
                  />
                  <h1>{userName}</h1>
                </div>
              );
            },
          },
          {
            id: "leaveType",
            accessorFn: (row) => row.leaveTypeName,
            enableSorting: false,
            header: () => <span>Leave Type</span>,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "availableBalance",
            accessorFn: (row) => `${row.ytdBalance} Day`,
            enableSorting: false,
            header: () => <span>Available Balance</span>,
            cell: (status) => {
              return cell(status);
            },
          },
          {
            id: "deductFromLeave",
            accessorFn: (row) => row.deductFromLeave,
            enableSorting: false,
            header: () => <span>Deduct From Leave</span>,
            cell: (status) => {
              return (
                <div className="w-full">
                  <h1>{totalLeaveDay} Day</h1>
                </div>
              );
            },
          },
          {
            id: "remainingBalance",
            accessorFn: (row) => row.ytdBalance,
            enableSorting: false,
            header: () => <span>Remaining Balance</span>,
            cell: (status) => {
              return (
                <h1 className="font-semibold">
                  {status.getValue() - totalLeaveDay} Day
                </h1>
              );
            },
          },
        ]}
        data={dataTable}
      />
    </React.Fragment>
  );
};
