import React from "react";
import { LeaveTypeSettingsForm } from "@bluesilodev/timhutcomponents";
import { useAlertApi } from "services/alert-api";
import {
  useAddLeaveTypeMutation,
  useUpdateLeaveTypeMutation,
} from "store/apis/leaveTypeApi";

export const LeaveTypeAddModalForm = ({
  addLeaveTypeModal,
  addLeaveTypeOpen,
}) => {
  const [addLeaveType, addLeaveTypeResults] = useAddLeaveTypeMutation();

  const alertSuccessClose = () => {
    addLeaveTypeOpen(false);
  };

  useAlertApi(addLeaveTypeResults, alertSuccessClose);

  const clickSaveAddLeaveType = (values) => {
    addLeaveType({
      name: values.name,
      defaultAmount: values.defaultAmount,
      gainPerMonth: values.gainPerMonth,
      expiredCarryOver: values.expiredCarryOver,
      maxCarryOver: values.maxCarryOver,
      isFullLeaveAmount: values.isFullLeaveAmount,
      periodOfEmployments: values.periodOfEmployments.map((val) => ({
        from: val.from,
        to: val.to,
        equal: val.equal,
      })),
    });
  };

  return (
    <React.Fragment>
      {addLeaveTypeModal && (
        <LeaveTypeSettingsForm
          visibleModal={addLeaveTypeModal}
          setVisibleModal={addLeaveTypeOpen}
          initialValue={{ expiredCarryOver: 0 }}
          onSubmit={clickSaveAddLeaveType}
          type={"add"}
        />
      )}
    </React.Fragment>
  );
};

export const LeaveTypeEditModalForm = ({
  leaveTypeDetailsModal,
  leaveTypeDetailsOpen,
  leaveTypeDetailsEdit,
}) => {
  const [updateLeaveType, updateLeaveTypeResults] =
    useUpdateLeaveTypeMutation();

  const alertSuccessClose = () => {
    leaveTypeDetailsOpen(false);
  };

  useAlertApi(updateLeaveTypeResults, alertSuccessClose);

  const clickSaveEditLeaveType = (values) => {
    updateLeaveType({
      leaveTypeId: values.leaveTypeId,
      name: values.name,
      defaultAmount: values.defaultAmount,
      gainPerMonth: values.gainPerMonth,
      expiredCarryOver: values.expiredCarryOver,
      maxCarryOver: values.maxCarryOver,
      isFullLeaveAmount: values.isFullLeaveAmount,
      periodOfEmployments: values.periodOfEmployments.map((val) => ({
        from: val.from,
        to: val.to,
        equal: val.equal,
      })),
    });
  };

  return (
    <React.Fragment>
      {leaveTypeDetailsModal && (
        <LeaveTypeSettingsForm
          visibleModal={leaveTypeDetailsModal}
          setVisibleModal={leaveTypeDetailsOpen}
          initialValue={leaveTypeDetailsEdit}
          onSubmit={clickSaveEditLeaveType}
          type={"edit"}
        />
      )}
    </React.Fragment>
  );
};
